import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import Select from 'react-select';
import {baseUrl } from "../../../comman/config";
import { useNavigate, Link } from 'react-router-dom';

export default function Form({detail}) {
   
    const [formData, setFormData] = useState({
        event_name : '',
        start_date : '',
        end_date : '',
        maharaj_name: [],
        bowl_type: ''
    });
    const [validationErrors, setValidationErrors] = useState({
        event_name: false,
        start_date: false,
        end_date: false,
        maharaj_name: false,
        bowl_type: false

    });
    const [popupOpen , setPopupOpen] = useState(false);
    const [maharajList, setMaharajList] = useState([]);
    const [bowlList, setBowlList] = useState([]);
    const formRef = useRef(null);
    const[error, setError] = useState(null);
    
    const navigate = useNavigate();
    
    useEffect(()=>{
        getMaharajList();
        getBowlMasterList();
    },[])

    useEffect(() => {
        if(detail){
            const bowlObject = bowlList.find((item) => item.id == detail[0].bowl_type?.id);
            const bowl_type = bowlObject ? {value : bowlObject.id, label : bowlObject.name} : {value : "", label : ""}
            setFormData({
                event_name: detail[0].event_name || '',
                start_date: detail[0].start_date || '',
                end_date: detail[0].end_date || '',
                maharaj_name : detail[0].maharaj.map((item) => {return {value : item.id, label : item.maharaj_name}}) || [],
                bowl_type : bowl_type
            });
        }
        // console.log(detail[0]);
    }, [detail, maharajList, bowlList]);

    // Clear error after 3 seconds
    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError(null);
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    const getMaharajList = async () => {
        const response = await axios.get(`${baseUrl}/maharaj/list`);
        const filteredList = response.data.data.filter((maharaj)=> maharaj.status === 1)
        setMaharajList(filteredList);
    }   

    const getBowlMasterList = async () => {
        const response = await axios.get(
            `${baseUrl}/comman/list?table=bowl_master&condition=status=1`
          );
          setBowlList(response.data.data);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const newValidationErrors = {
            event_name: formData.event_name === '',
            start_date: formData.start_date === '',
            end_date : formData.end_date === '',
            maharaj_name : formData.maharaj_name.length === 0,
        };
        setValidationErrors(newValidationErrors);
        const hasErrors = Object.values(newValidationErrors).some((error) => error);
        if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }else {
            const today = new Date().toISOString().slice(0, 10);
            const event_start_date = formData.start_date;
            const role = localStorage.getItem('userRole');
            if(today >= event_start_date && role != 1){
                
                formData.bowl_type = ''
                
            }
            let url;
            try {
                setPopupOpen(true);
                if(detail){
                    url = `event/edit/${detail[0].id}`;
                }else{
                    url = "event/add";
                }
                await axios.post(`${baseUrl}/${url}`,{
                    formData: formData,
                });
                setPopupOpen(false);
                navigate("/event_master/list");
            } catch (error) {

                if (error.response?.status === 400) {
                    setError(error.response.data.msg); // This will show "Store name already exists"
                } else {
                    setError('An error occurred while adding the store');
                }
                setPopupOpen(false);
                console.log(error);
                
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
    
            }));
      
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

    const handleChangeMultiSelect = (selected) => {
        
        setFormData((prevData) => ({
            ...prevData,
            maharaj_name: selected,

        }));
        
    };

    const handleSelectChange = (data, option) => {
        setFormData((prevData) => ({
            ...prevData,
            bowl_type: data,

        }));
    };

  return (
      <>
          {/* Error Alert */}
          {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                  <span className="block sm:inline">{error}</span>
                  <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setError(null)}>
                      <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <title>Close</title>
                          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                      </svg>
                  </span>
              </div>
          )}
 
 <form ref={formRef}>
                <div className="space-y-12">
                    <div>
                        <div className="mt-10 space-y-8 lg:space-y-0 lg:flex lg:gap-x-5 lg:flex-wrap ">
                            <div className="sm:col-span-2">
                                <label htmlFor="menuitem_eng" className="block text-sm font-medium leading-6 text-gray-900">Event Name</label>
                                <div className="mt-2">
                                    <input
                                      type="text"
                                      name="event_name"
                                      id="event_name"
                                      placeholder='Event Name'
                                      value={formData.event_name}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.event_name ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.event_name && <div className="text-red-500">Please enter a event name.</div>}
                            </div>
                            
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="menuitem_category" className="block text-sm font-medium leading-6 text-gray-900">Start Date</label>
                                <div className="mt-2">
                                <input
                                      id="start_date"
                                      name="start_date"
                                      type="date"
                                      value={formData.start_date}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.start_date ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.start_date && <div className="text-red-500">Please enter Start Date.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="menuitem_category" className="block text-sm font-medium leading-6 text-gray-900">End Date</label>
                                <div className="mt-2">
                                    <input
                                      id="end_date"
                                      name="end_date"
                                      type="date"
                                      value={formData.end_date}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.end_date ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.end_date && <div className="text-red-500">Please enter end date.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="maharaj_name" className="block text-sm font-medium leading-6 text-gray-900">Maharaj Name</label>
                                <div className="mt-2">
                                        <Select
                                            isMulti
                                            classNamePrefix="select"
                                            className='basic-multi-select'
                                            value={formData.maharaj_name}
                                            name="maharaj_name"
                                            onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions)}
                                            options={maharajList.map(item => ({
                                                value: item.id,
                                                label: item.maharaj_name
                                            }))}
                                            placeholder="Maharaj List"
                                            filterOption={(option, inputValue) => {
                                                return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                                            }}
                                        />  
                                    
                                    </div>
                                {validationErrors.maharaj_name && <div className="text-red-500">Please select a maharaj name.</div>}
                            </div>
                            <div className="sm:col-span-2 flex flex-col justify-end">
                                <Link to="#">
                                    <button onClick={() => window.open("/maharaj_master/list", "_blank", "noopener,noreferrer")} className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Assign Item Category</button>
                                </Link>  
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="bowl_type" className="block text-sm font-medium leading-6 text-gray-900">Event Bowl Type</label>
                                <div className="mt-2">
                                        <Select
                                            
                                            classNamePrefix="select"
                                            className='basic-multi-select'
                                            value={formData.bowl_type}
                                            name="bowl_type"
                                            onChange={(selectedOption, name) => {
                                                handleSelectChange(selectedOption, name);
                                            }}
                                            options={bowlList.map(item => ({
                                                value: item.id,
                                                label: item.name
                                            }))}
                                            placeholder="Bowl List"
                                            filterOption={(option, inputValue) => {
                                                return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                                            }}
                                        />  
                                    
                                    </div>
                                {validationErrors.bowl_type && <div className="text-red-500">Please select a bowl type.</div>}
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <Link to="/event_master/list">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                    </Link>
                    <button
                    onClick={handleSubmit}
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                </div>
            </form>
            </>
            )
        }