import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { baseUrl } from "../../comman/config";
import { useNavigate, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../Loader";

export default function Form({ detail }) {
  const today = new Date().toISOString().split("T")[0];
  const [formData, setFormData] = useState({
    currentDate:today,
    date: today,
    session: {
      label: "",
      value: ""
    },
    event: '',
    // menu_category_id : '',
    // menu_item_id : '',
    // default_method: '',
    // measurements: []
  });
  const [rows, setRows] = useState([
    { id: 1, track_for_report: false, menuitem_category : '', maharaj_id:'',  menu_item_id: "", measurements: [], fg_consumption_per_person_kgs : "", fg_consumption_per_person_ltr : "", fg_consumption_per_person_nos : "", avg_density : "", avg_qty_in_1_bowl_ltr : '', avg_qty_in_1_bowl_kgs : '', avg_qty_in_1_bowl_nos : ''},
  ]);
  const [rowCount, setRowCount] = useState(1);
  const [rowDisable, setRowDisable] = useState([]);
  const [validationErrors, setValidationErrors] = useState({
    date: false,
    session: false,
    event: false,
    // menu_category_id: false,
    menu_item_id: false,
    // default_method: ''
  });
  // const [menuitemCategory, setMenuitemCategory] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [bowlList, setBowlList] = useState([]);
  const [menuitemCategory, setMenuitemCategory] = useState([]);
  const [maharajList, setMaharajList] = useState([])
  const [menuitemList, setMenuitemList] = useState([]);
  const [filteredMenuItemList, setFilteredMenuItemList] = useState([])
  const [methodList, setMethodList] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();
  useEffect(() => {
    getEventList();
    getSessionList();
    getMenuitemCategory();
    getMaharajList()
    getMenuItem();
    getMethodList();
    getBowlMasterList();
  }, []);

  useEffect(() => {

    const params = new URLSearchParams(window.location.search);
    const sessionValue = params.get("session");
    const sessionLabel = params.get("sessionLabel");
    const dateParam = params.get("date");

    const fetchData = async () => {
      if (detail) {
        setLoading(true)
        const formateDate = new Date(detail[0].date)
          .toISOString()
          .split("T")[0];
        const sessionId = detail[0].session;
        const sessionItem = sessionList.find((item) => item.name === sessionId);
        const sessionObject = sessionItem
          ? { value: sessionItem.id, label: sessionItem.name }
          : { value: "", label: "" };
          const event = detail[0].event;
          const eventItem = eventList.find((item) => item.id == event);
          const eventObject = eventItem
            ? { value: eventItem.id, label: eventItem.event_name }
            : "";
        if (sessionItem) {
          try {
            const mealCondition = ` m.date='${formateDate}' AND m.session='${sessionItem.id}'`;
            const response = await axios.get(`${baseUrl}/meal_master/item_list?condition=${mealCondition}`);
            const list = response.data.data;
            const newArray = [];

          for (const [index, item] of list.entries()) {
            // const newArray = await Promise.all(list.map(async(item, index) => {
              const menuitem = menuitemList.find(
                (v) => v.id == item.menu_item_id
              );
              const menuitemObject = menuitem
                ? {
                    value: menuitem.id,
                    label: `${menuitem.item_name} (${menuitem.eng_name})`,
                  }
                : { value: "", label: "" };

              const bowlItem = bowlList.find(
                (v) => v.id == (item.event_bowl_type ? item.event_bowl_type : detail[0].event_deail?.bowl_type)
              );
              const bowlObject = bowlItem ? {value : bowlItem.id, label : bowlItem.name} : { value: "", label: "" };
              // console.log(bowl_type);
              const measurementsArray = item.measurements?.split(",").map(Number) ?? [];
              let fg_consumption_per_person_kgs;
              let avg_density;
              let fg_consumption_per_person_ltr;
              if(!item.fg_consumption_per_person_kgs && (measurementsArray.includes(1) || measurementsArray.includes(4))){
                const condition = `menu_item_id = '${menuitemObject.value}' AND method = '1'`;
                const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
                fg_consumption_per_person_kgs = response.data.data?.[0]?.fg_consumption_per_person ?? '';
                avg_density = response.data.data?.[0]?.avg_density ?? '';
                // fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(2) : 0;
              }else{
                fg_consumption_per_person_kgs = item.fg_consumption_per_person_kgs;
                avg_density = item.avg_density;
                // fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(2) : 0;
              }

              // 
              if(!item.fg_consumption_per_person_ltr && (measurementsArray.includes(2) || measurementsArray.includes(5))){
                // console.log("rerer");
                // const condition = `menu_item_id = '${menuitemObject.value}' AND method = '2'`;
                // const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
                // fg_consumption_per_person_kgs = response.data.data?.[0]?.fg_consumption_per_person ?? '';
                // avg_density = response.data.data?.[0]?.avg_density ?? '';
                fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(3) : 0;
              }else{
                fg_consumption_per_person_ltr = item.fg_consumption_per_person_ltr;
                avg_density = item.avg_density;
              }

              let fg_consumption_per_person_nos;
              if(!item.fg_consumption_per_person_nos && (measurementsArray.includes(3) || measurementsArray.includes(6))){
                const condition = `menu_item_id = '${menuitemObject.value}' AND method = '3'`;
                const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
                fg_consumption_per_person_nos = response.data.data?.[0]?.fg_consumption_per_person ?? '';
                avg_density = response.data.data?.[0]?.avg_density ?? '';
              }else{
                fg_consumption_per_person_nos = item.fg_consumption_per_person_nos;
                avg_density = item.avg_density;
              }
              const avg_qty_in_1_bowl_ltr = item.avg_qty_in_1_bowl_ltr ? item.avg_qty_in_1_bowl_ltr : (bowlItem?.bowl_marked_volume ?? 0);
              let density, avg_qty_in_1_bowl_kgs;
              if(item.avg_qty_in_1_bowl_kgs){
                avg_qty_in_1_bowl_kgs = item.avg_qty_in_1_bowl_kgs;
              }else{
                if((measurementsArray.includes(2) || measurementsArray.includes(5)) && (measurementsArray.includes(1) || measurementsArray.includes(4))){
  
                  const response = await axios.get(`${baseUrl}/reports/master_report?fromDate=${item.date}&toDate=${item.date}&session=${sessionItem.id}&item=${JSON.stringify([menuitemObject])}`);
                  const masterDetail = response.data.data ?? [];
                  if(masterDetail.length > 0){
                    const std_density = masterDetail[0].std_density ?? 0;
                    const live_density = masterDetail[0].density ?? 0;
                    density = live_density != 0 ? live_density : (std_density != 0 ? std_density : 0);
                  }else{
                    density = 1;
                  }
                }else{
                  density = 1;
                }
                avg_density = item.avg_density ? item.avg_density : density;
                avg_qty_in_1_bowl_kgs = (avg_qty_in_1_bowl_ltr * density).toFixed(2);
              }

              let avg_qty_in_1_bowl_nos;
              if(item.avg_qty_in_1_bowl_nos){
                avg_qty_in_1_bowl_nos = item.avg_qty_in_1_bowl_nos;
              }else{
                if((measurementsArray.includes(3) || measurementsArray.includes(6))){
                  const method = measurementsArray.includes(3) ? 3 : 6;
                  // console.log(item.menu_item_id, item.date, sessionItem.id,  method);
                  const condition = `menu_item_id = '${item.menu_item_id}' AND date = '${item.date}' AND session = '${sessionItem.id}' AND method = '${method}'`;
                  const response = await axios.get(
                    `${baseUrl}/comman/list?table=finished_goods&condition=${condition}`
                  );
                  const fgData = response.data.data;
                  const weight_1_filled_bowl = fgData.reduce((accumulator, currentObject) => {
                      return accumulator + Number(currentObject.weight_of_1_filled_bowl);
                  }, 0);
                  const finished_roti_weight = fgData.reduce((accumulator, currentObject) => {
                      return accumulator + Number(currentObject.avg_finished_roti_weight);
                  }, 0);
                
                  const bowl_weight = Number(bowlItem?.bowl_weight_empty ?? 0);
                  avg_qty_in_1_bowl_nos = (weight_1_filled_bowl - bowl_weight) / (finished_roti_weight * 1000);
                  if (isNaN(avg_qty_in_1_bowl_nos) || !isFinite(avg_qty_in_1_bowl_nos)) {
                    avg_qty_in_1_bowl_nos = 0;
                } else {
                    avg_qty_in_1_bowl_nos = avg_qty_in_1_bowl_nos.toFixed(2); // Ensure 2 decimal places
                }
                }
              }
              
              const condition = `menu_item_id = '${menuitemObject.value}' AND date = '${formateDate}' AND session = '${sessionItem.id}'`;
              const fgDetailResponse = await axios.get(`${baseUrl}/comman/list?table=finished_goods&condition=${condition}`);
              
              // console.log(item)
              newArray.push({
                id: index + 1,
                track_for_report: item?.track_for_report == 1 ? true : false ?? false,
                menu_item_id: menuitemObject,
                menuitem_category:item.menuitem_category,
                maharaj_id:item.maharaj,
                measurements: measurementsArray,
                fg_consumption_per_person_kgs : fg_consumption_per_person_kgs,
                fg_consumption_per_person_ltr : fg_consumption_per_person_ltr,
                fg_consumption_per_person_nos : fg_consumption_per_person_nos,
                avg_density : avg_density,
                event_bowl_type  :bowlObject,
                avg_qty_in_1_bowl_ltr : avg_qty_in_1_bowl_ltr,
                avg_qty_in_1_bowl_kgs: avg_qty_in_1_bowl_kgs,
                avg_qty_in_1_bowl_nos:avg_qty_in_1_bowl_nos,
                db_id : item.id,
                disable : fgDetailResponse.data.data.length > 0 ? true : false
              });
            };
            // console.log(newArray);
            setRows(newArray);
            setRowDisable(newArray.filter(item => item.disable).map(item => item.id));
            setRowCount(newArray.length);
            const newState = {
              currentDate: formateDate || today,
              date: formateDate || today,
              session: sessionObject || "",
              event : eventObject || ""
            };

            setFormData(newState);
          } catch (error) {
            // Handle errors
            console.error("Error fetching meal items:", error);
          }
        }
        setLoading(false)
      }
      else {
        setFormData((prev) => ({
          ...prev,
          session: {
            label: sessionLabel || "",
            value: sessionValue || ""
          },
          date: dateParam || today,
        }));
      }
    };

    fetchData();
  }, [detail, eventList,bowlList,sessionList, menuitemList]);

  const getEventList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=event_master&condition=status=1`
    );
    setEventList(response.data.data);
  }
  const getSessionList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=session_master&condition=status=1`
    );
    setSessionList(response.data.data);
  };

  const getMenuitemCategory = async () => {
    const response = await axios.get(`${baseUrl}/comman/list?table=item_category_master&condition=status=1`);
    setMenuitemCategory(response.data.data);
  }

  const getBowlMasterList = async () => {
    const response = await axios.get(
        `${baseUrl}/comman/list?table=bowl_master&condition=status=1`
      );
      setBowlList(response.data.data);
}

  const getMaharajList = async () => {
    const response = await axios.get(`${baseUrl}/maharaj/list`);
    const filteredList = response.data.data.filter((maharaj)=> maharaj.status === 1)
    setMaharajList(filteredList);
  } 

  const getMenuItem = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=item_master&condition=status=1`
    );
    setMenuitemList(response.data.data);
  };

  const getMethodList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=method_master&condition=status=1`
    );
    setMethodList(response.data.data);
  };

  const handleRefresh = async (e, id) => {
    e.preventDefault();
    setLoading(true)
    if(id){
      const newArray = await Promise.all(rows.map(async(item, index) => {
          if(item.menu_item_id.value === id){
            const measurementsArray = item.measurements ?? [];
              let fg_consumption_per_person_kgs;
              let avg_density;
              let fg_consumption_per_person_ltr;
              if(measurementsArray.includes(1)){
                const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '1'`;
                const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
                console.log('Consumption Response',response.data.data)
                fg_consumption_per_person_kgs = response.data.data?.[0]?.fg_consumption_per_person ?? '';
                avg_density = response.data.data?.[0]?.avg_density ?? '';
                // fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(2) : 0;
              }
              if(measurementsArray.includes(4)){
                const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '4'`;
                const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
                console.log('Consumption Response',response.data.data)
                fg_consumption_per_person_kgs = response.data.data?.[0]?.fg_consumption_per_person ?? '';
                avg_density = response.data.data?.[0]?.avg_density ?? '';
              }
        
              // 
              if((measurementsArray.includes(2) || measurementsArray.includes(5))){
                
                fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(3) : 0;
              }
        
              let fg_consumption_per_person_nos;
              if((measurementsArray.includes(3))){
                const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '3'`;
                const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
                fg_consumption_per_person_nos = response.data.data?.[0]?.fg_consumption_per_person ?? '';
                avg_density = response.data.data?.[0]?.avg_density ?? '';
              }
              if((measurementsArray.includes(6))){
                const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '6'`;
                const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
                fg_consumption_per_person_nos = response.data.data?.[0]?.fg_consumption_per_person ?? '';
                avg_density = response.data.data?.[0]?.avg_density ?? '';
              }
        
              return {
                ... item,
                fg_consumption_per_person_kgs : fg_consumption_per_person_kgs,
                fg_consumption_per_person_ltr : fg_consumption_per_person_ltr,
                fg_consumption_per_person_nos : fg_consumption_per_person_nos,
                avg_density : avg_density,
              };
          }else{
            return item;
          }
      }));
      setRows(newArray);
    }
    setLoading(false)
    // const newArray = await Promise.all(rows.map(async(item, index) => {
    //   const measurementsArray = item.measurements ?? [];
    //   let fg_consumption_per_person_kgs;
    //   let avg_density;
    //   let fg_consumption_per_person_ltr;
    //   if((measurementsArray.includes(1) || measurementsArray.includes(4))){
    //     const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '1'`;
    //     const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
    //     fg_consumption_per_person_kgs = response.data.data?.[0]?.fg_consumption_per_person ?? '';
    //     avg_density = response.data.data?.[0]?.avg_density ?? '';
    //     // fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(2) : 0;
    //   }

    //   // 
    //   if((measurementsArray.includes(2) || measurementsArray.includes(5))){
        
    //     fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(3) : 0;
    //   }

    //   let fg_consumption_per_person_nos;
    //   if((measurementsArray.includes(3) || measurementsArray.includes(6))){
    //     const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '3'`;
    //     const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
    //     fg_consumption_per_person_nos = response.data.data?.[0]?.fg_consumption_per_person ?? '';
    //     avg_density = response.data.data?.[0]?.avg_density ?? '';
    //   }

    //   return {
    //     ... item,
    //     fg_consumption_per_person_kgs : fg_consumption_per_person_kgs,
    //     fg_consumption_per_person_ltr : fg_consumption_per_person_ltr,
    //     fg_consumption_per_person_nos : fg_consumption_per_person_nos,
    //     avg_density : avg_density,
    //   };
    // }));
    // setRows(newArray);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const gujaratiRegex = /^[\u0A80-\u0AFF\s]+$/;
    // const gujaratiRegex = /^[\u0A80-\u0AFF\s()+\-]*$/;
    const newValidationErrors = {
      currentDate: formData.date === "",
      date: formData.date === "",
      session: formData.session === "",
      event : formData.event === "",
    };
    console.log('submitted form Data',formData)
    setValidationErrors(newValidationErrors);
    const hasErrors = Object.values(newValidationErrors).some((error) => error);
    if (hasErrors) {
      // Find the first invalid input element
      const firstInvalidInput = formRef.current.querySelector(".invalid");
      if (firstInvalidInput) {
        // Scroll to the first invalid input element
        firstInvalidInput.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    } else {
      console.log(rows);
      let url;
      try {
        setLoading(true)
        setPopupOpen(true);
        if (detail) {
          url = `meal_master/edit/${detail[0].id}`;
        } else {
          url = "meal_master/add";
        }
        const response = await axios.post(`${baseUrl}/${url}`, {
          formData: formData,
          rows: rows,
        });
        setLoading(false)
        setPopupOpen(false);
        const status = response?.data?.status 
        if(status == true){
          toast.success("Data successfully saved!");
        }else if(status == false){
          toast.error(response?.data?.msg)
        }
        // navigate("/meal_master/list");
      } catch (error) {
        toast.error("Failed to submit the form.");
        setPopupOpen(false);
        console.log(error);
      }
    }
  };

  const getMaharajMenuItemCategory = async (value)=> {
    const condition = `id = '${value}'`;
    const response = await axios.get(`${baseUrl}/comman/list?table=item_category_master&condition=${condition}`);

    //update the maharaj for the item category
    setFormData((prevData) => ({
      ...prevData,
      maharaj_id: response.data.data[0].maharaj || '',
    }));

    //update menu item list for that category
    const filteredMenuItemList = menuitemList.filter((menuItem)=>menuItem.category == value)
    setMenuitemList(filteredMenuItemList)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleSelectChange = (data, option) => {
    setFormData((prevData) => ({
      ...prevData,
      [option.name]: data,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [option.name]: false,
    }));

    if(option.name == "event"){
      setRows((prevRows) => {
        return prevRows.map((row) => { 
          const eventBowlItem = eventList.find(
            (v) => v.id == data.value
          )?.bowl_type ?? "";
          const bowlItem = bowlList.find(
            (v) => v.id == eventBowlItem
          );
          const bowlObject = bowlItem ? {value : bowlItem.id, label : bowlItem.name} : { value: "", label: "" };
           return {...row, event_bowl_type : bowlObject}
          })
      })
    }
  };
  const handleSelectRowChange = async (data, option, id) => {
    const updatedRows = [];
      for (let row of rows) {
        if(row.id === id) {
         
          const bowlItem = bowlList.find((v) => v.id == data.value);
          const measurementsArray = row.measurements ?? [];
          const menuitem = menuitemList.find((v) => v.id == row.menu_item_id);
          const menuitemObject = menuitem
            ? {
                value: menuitem.id,
                label: `${menuitem.item_name} (${menuitem.eng_name})`,
              }
            : { value: "", label: "" };
          const sessionId = (detail && detail.length > 0 && detail[0].session) ? detail[0].session : formData.session?.value; 
          const sessionItem = sessionList.find((item) => item.name === sessionId);
       
          let density;
          if((measurementsArray.includes(2) || measurementsArray.includes(5)) && (measurementsArray.includes(1) || measurementsArray.includes(4))){
            const response = await axios.get(`${baseUrl}/reports/master_report?fromDate=${row.date}&toDate=${row.date}&session=${sessionId}&item=${JSON.stringify([menuitemObject])}`);
            const masterDetail = response.data.data ?? [];
            if(masterDetail.length > 0){
              const std_density = masterDetail[0].std_density ?? 0;
              const live_density = masterDetail[0].density ?? 0;
              density = live_density != 0 ? live_density : (std_density != 0 ? std_density : 0);
            }else{
              density = 1;
            }
          }else{
            density = 1;
          }
          let avg_qty_in_1_bowl_nos = 0;
          if((measurementsArray.includes(3) || measurementsArray.includes(6))){
            const method = measurementsArray.includes(3) ? 3 : 6;
            // console.log(row)
            const condition = `menu_item_id = '${row.menu_item_id.value}' AND date = '${formData.date}' AND session = '${sessionId}' AND method = '${method}'`;
            // console.log(condition)
            const response = await axios.get(
              `${baseUrl}/comman/list?table=finished_goods&condition=${condition}`
            );
            const fgData = response.data.data;
            // console.log('fgData',fgData)
            const weight_1_filled_bowl = fgData.reduce((accumulator, currentObject) => {
                return accumulator + Number(currentObject.weight_of_1_filled_bowl);
            }, 0);
            const finished_roti_weight = fgData.reduce((accumulator, currentObject) => {
                return accumulator + Number(currentObject.avg_finished_roti_weight);
            }, 0);
          
            const bowl_weight = Number(bowlItem?.bowl_weight_empty ?? 0);
            avg_qty_in_1_bowl_nos = (weight_1_filled_bowl - bowl_weight) / (finished_roti_weight * 1000);
            console.log('avg_qty_in_1_bowl_nos',avg_qty_in_1_bowl_nos,weight_1_filled_bowl,bowl_weight,finished_roti_weight)
            if (isNaN(avg_qty_in_1_bowl_nos) || !isFinite(avg_qty_in_1_bowl_nos)) {
              avg_qty_in_1_bowl_nos = 0;
          } else {
              avg_qty_in_1_bowl_nos = avg_qty_in_1_bowl_nos.toFixed(2); // Ensure 2 decimal places
          }
          }
          
          //         
          const avg_qty_in_1_bowl_kgs = ((bowlItem.bowl_marked_volume ?? 0) * row.avg_density).toFixed(2);
          console.log('avg_qty_in_1_bowl_kgs',avg_qty_in_1_bowl_kgs,avg_qty_in_1_bowl_nos)
          updatedRows.push({...row, event_bowl_type : data, avg_qty_in_1_bowl_ltr : bowlItem.bowl_marked_volume ?? 0, avg_qty_in_1_bowl_kgs: avg_qty_in_1_bowl_kgs, avg_qty_in_1_bowl_nos: avg_qty_in_1_bowl_nos});
        }else{
          updatedRows.push({...row});
        }
        };
        setRows(updatedRows);
    // })
  }

  const handleMenuitemCategoryChange = async (id,data) => {
    const condition = `id = '${data.value}'`;
    const response = await axios.get(`${baseUrl}/comman/list?table=item_category_master&condition=${condition}`);
    console.log(data)
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, menuitem_category: data.value, maharaj_id: response.data.data[0].maharaj || '',menu_item_id:'' } : row
    );
    setRows(updatedRows);
    const filterMenuItemList = menuitemList.filter((menuItem)=> menuItem.category == data.value)
    setFilteredMenuItemList(filterMenuItemList)
  }

    

    const handleMaharajChange = (id,data) => {
      const updatedRows = rows.map((row) =>
        row.id === id ? { ...row, maharaj_id: data.value } : row
      );
      setRows(updatedRows);
  }

  const handleMenuitemChange = (id, data) => {
    const checkMenuItem = rows.find(
      (item) => item.menu_item_id.value == data.value
    )
      ? true
      : false;

    if (!checkMenuItem) {
      const updatedRows = rows.map((row) =>
        row.id === id ? { ...row, menu_item_id: data } : row
      );
      setRows(updatedRows);
      if(updatedRows.find((item) => item.id === id)){
        callApi(updatedRows.find((item) => item.id === id));
      }
    } else {
      alert("menu item allready exist");
      return;
    }
  };

  const handleCheckboxChange = (id, methodId, isChecked) => {
    setRows((prevRows) => {
      return prevRows.map((row) => {
        if (row.id === id) {
          const updatedMeasurements = isChecked
            ? [...row.measurements, methodId]
            : row.measurements.filter(
                (measurementId) => measurementId !== methodId
              );
              callApi( { ...row, measurements: updatedMeasurements });
          return { ...row, measurements: updatedMeasurements };
        }
        
        return row;
      });
    });
    
  };

  const callApi = async(row) => {
    console.log('call API',row.menu_item_id,row.measurements,row)
    if(row.menu_item_id.value && row.measurements){
      
      if ((row.measurements.includes(1) || row.measurements.includes(4)) && (!row.fg_consumption_per_person_kgs || row.fg_consumption_per_person_kgs === '')) {
        console.log('second if ')
        const condition = `menu_item_id = '${row.menu_item_id.value}' AND method = '1'`;
        const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
        const value = response.data.data?.[0]?.fg_consumption_per_person ?? '';
        const avg_density = response.data.data?.[0]?.avg_density ?? '';
        const fg_consumption_per_person_ltr = avg_density > 0 ? (value / avg_density).toFixed(2) : 0;
        const bowlItem = bowlList.find((v) => v.id == row?.event_bowl_type?.value)
        console.log('row data',row)
        const avg_qty_in_1_bowl_ltr = (bowlItem?.bowl_marked_volume ?? 0) 
        const avg_qty_in_1_bowl_kgs = ((bowlItem?.bowl_marked_volume ?? 0) * avg_density).toFixed(2);
        setRows((prevRows) =>
          prevRows.map((r) =>
            r.id === row.id ? { ...r, ["fg_consumption_per_person_kgs"]: value, ["avg_density" ]: avg_density, ["fg_consumption_per_person_ltr"] : fg_consumption_per_person_ltr, ["avg_qty_in_1_bowl_kgs"] : avg_qty_in_1_bowl_kgs, ["avg_qty_in_1_bowl_ltr"] : avg_qty_in_1_bowl_ltr } : r
          )
        );
      }
      // if ((row.measurements.includes(2) || row.measurements.includes(5)) && row.fg_consumption_per_person_ltr == '') {
      //   const condition = `menu_item_id = '${row.menu_item_id.value}' AND method = '2'`;
      //   const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
      //   const value = response.data.data?.[0]?.fg_consumption_per_person ?? '';
      //   const avg_density = response.data.data?.[0]?.avg_density ?? '';

      //   setRows((prevRows) =>
      //     prevRows.map((r) =>
      //       r.id === row.id ? { ...r, ["fg_consumption_per_person_ltr"]: value, ["avg_density" ]: avg_density } : r
      //     )
      //   );
        
      // }
      if ((row.measurements.includes(3) || row.measurements.includes(6)) && row.fg_consumption_per_person_nos == '') {
        const condition = `menu_item_id = '${row.menu_item_id.value}' AND (method = '3' OR method = '6')`;
        const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
        console.log('Readmade nos repsonse',response.data.data)
        const value = response.data.data?.[0]?.fg_consumption_per_person ?? '';
        // const avg_density = response.data.data?.[0]?.avg_density ?? '1.000';
        const avg_density = '1.000'

        setRows((prevRows) =>
          prevRows.map((r) =>
            r.id === row.id ? { ...r, ["fg_consumption_per_person_nos"]: value, ["avg_density" ]: avg_density } : r
          )
        );
        
      }
      
     
    }
   
  }
  const handleFgChange = (e, id) => {
    const { value, name } = e.target;
    

    setRows((prevRows) =>
      prevRows.map((row) =>{
        if(row.id === id) {
          const updatedRow = { ...row, [name]: value };
          if (name === 'fg_consumption_per_person_kgs') {
            const fg_consumption_per_person_kgs = updatedRow.fg_consumption_per_person_kgs || 0;
            const avg_density = updatedRow.avg_density; // Avoid division by zero
            updatedRow.fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(3) : 0;
          }else if(name === 'fg_consumption_per_person_ltr'){
            const fg_consumption_per_person_ltr = updatedRow.fg_consumption_per_person_ltr || 0;
            const avg_density = updatedRow.avg_density;
            updatedRow.fg_consumption_per_person_kgs = avg_density > 0 ? ( fg_consumption_per_person_ltr * avg_density).toFixed(3) : 0;
          }else if(name === 'avg_density'){
            const fg_consumption_per_person_ltr = updatedRow.fg_consumption_per_person_ltr || 0;
            const avg_density = updatedRow.avg_density;
            updatedRow.fg_consumption_per_person_kgs = avg_density > 0 ? ( fg_consumption_per_person_ltr * avg_density).toFixed(3) : 0;
            updatedRow.avg_qty_in_1_bowl_kgs = avg_density > 0 ? ( updatedRow.avg_qty_in_1_bowl_ltr * avg_density).toFixed(3) : 0;
          }else if(name === 'avg_qty_in_1_bowl_kgs'){
            const avg_qty_in_1_bowl_kgs = updatedRow.avg_qty_in_1_bowl_kgs || 0;
            const avg_density = updatedRow.avg_density;
            updatedRow.avg_qty_in_1_bowl_ltr = avg_density > 0 ? (avg_qty_in_1_bowl_kgs / avg_density).toFixed(3) : 0;
          }else if(name === 'avg_qty_in_1_bowl_ltr'){
            const avg_qty_in_1_bowl_ltr = updatedRow.avg_qty_in_1_bowl_ltr || 0;
            const avg_density = updatedRow.avg_density;
            updatedRow.avg_qty_in_1_bowl_kgs = avg_density > 0 ? (avg_qty_in_1_bowl_ltr / avg_density).toFixed(3) : 0;
          }
          console.log(updatedRow);
          return updatedRow;
        }else{
          return row;
        } 
      }
      )
    );
  };
  const handleAddRow = (e) => {
    e.preventDefault();
    setRowCount(rowCount + 1);
    const eventBowlItem = eventList.find(
      (v) => v.id == formData.event?.value
    )?.bowl_type ?? "";
    const bowlItem = bowlList.find(
      (v) => v.id == eventBowlItem
    );
    const bowlObject = bowlItem ? {value : bowlItem.id, label : bowlItem.name} : { value: "", label: "" };
    setRows([
      ...rows,
      { id: rowCount + 1, track_for_report: false, menu_item_id: "", measurements: [], fg_consumption_per_person_kgs: '', fg_consumption_per_person_ltr : '', fg_consumption_per_person_nos : '', avg_density : '',event_bowl_type: bowlObject  },
    ]);
  };

  const handleRemoveRow = async(id) => {
    setRows(rows.filter((row) => row.id !== id));
    // console.log(rows.filter((row) => row.id == id))
    const db_id = rows.filter((row) => row.id === id)?.[0].db_id;
    if(db_id){
      try {
        // setPopupOpen(true);
  
        const response = await axios.post(`${baseUrl}/meal_master/status/${db_id}`);
        toast.success("Data successfully saved!");
      } catch (error) {
        toast.error("Failed to submit the form.");
        // setPopupOpen(false);
        console.log(error);
      }
    }
  };

  const isRowValid = (row) => {
    if (row.menu_item_id !== "") {
      return true;
    }
    return false;
  };
  const isAddRowDisabled = () => {
    return rows.some((row) => !isRowValid(row));
  };

  const handleTrackForReportChange = (id, isChecked) => {
    setRows((prevOptions) =>
        prevOptions.map((option) =>
            option.id === id
                ? { ...option, track_for_report: isChecked }
                : option
        )
    );
};

  return (
    <form ref={formRef}>
      {loading && <Loader/>}
      <Toaster position="top-center" reverseOrder={false} />
      <div className="space-y-12">
        <div>
          
          <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
          <div className="col-span-1 lg:col-span-2">
              <label
                htmlFor="event"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Event
              </label>
              <div className="mt-2">
                <Select
                  classNamePrefix="select"
                  value={formData.event}
                  name="event"
                  onChange={(selectedOption, name) => {
                    handleSelectChange(selectedOption, name);
                  }}
                  options={eventList.map((item) => ({
                    value: item.id,
                    label: item.event_name,
                  }))}
                  filterOption={(option, inputValue) => {
                    return option.data.label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase());
                  }}
                />
              </div>
              {validationErrors.event && (
                <div className="text-red-500">Please select a event.</div>
              )}
            </div>
            <div className="col-span-1 lg:col-span-2">
              <label
                htmlFor="date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Date
              </label>
              <div className="mt-2">
                <input
                  type="date"
                  name="date"
                  id="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  className={`block w-full rounded-md border ${
                    validationErrors.date ? "border-red-500" : "border-gray-300"
                  } py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                />
              </div>
              {validationErrors.date && (
                <div className="text-red-500">Please enter date.</div>
              )}
            </div>
            <div className="col-span-1 lg:col-span-2">
              <label
                htmlFor="session"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Session
              </label>
              <div className="mt-2">
                <Select
                  classNamePrefix="select"
                  value={formData.session}
                  name="session"
                  onChange={(selectedOption, name) => {
                    handleSelectChange(selectedOption, name);
                  }}
                  options={sessionList.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  filterOption={(option, inputValue) => {
                    return option.data.label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase());
                  }}
                />
              </div>
              {validationErrors.session && (
                <div className="text-red-500">Please select a session.</div>
              )}
            </div>
            <div className="col-span-1 sm:col-span-1">
            
              {/* <div className="mt-7">
                  <button
                    onClick={handleAddRow}
                    disabled={isAddRowDisabled()}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Add Row
                  </button>
                  </div> */}
              </div>
              <div className="col-span-1 sm:col-span-1">
            
              {/* <div className="mt-7">
                  <button
                    onClick={handleRefresh}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Refresh
                  </button>
                  </div> */}
              </div>
          </div>

          
          {rows.map((row) => (
            <div key={row.id}>
            <div
              className="mt-10 grid grid-cols-1 sm:grid-cols-4 gap-x-5 gap-y-8" 
              
            >
              <div className="col-span-1 sm:col-span-1">
                  <label htmlFor="menuitem_category" className="block text-sm font-medium leading-6 text-gray-900">Menuitem Category</label>
                  <div className="mt-2">
                      <Select
                        classNamePrefix="select"
                        name="menuitem_category"
                        value={menuitemCategory
                          .filter((item) => item.id == row.menuitem_category)
                          .map((item) => ({
                            value: item.id,
                            label: `${item.name} (${item.eng_name})`,
                          }))[0]}
                        options={menuitemCategory.map((item) => ({
                          value: item.id,
                          label: `${item.name} (${item.eng_name})`,
                        }))}
                        onChange={(selectedOption) => {
                          handleMenuitemCategoryChange(row.id, selectedOption);
                        }}
                        filterOption={(option, inputValue) => {
                          return option.data.label
                            .toLowerCase()
                            .includes(inputValue.toLowerCase());
                        }}
                      />
                      {/* <select 
                        id="menuitem_category"
                        name="menuitem_category"
                        type="text"
                        value={row.menuitem_category}
                        onChange={handleInputChange}
                        className={`block w-full rounded-md border ${validationErrors.menuitem_category ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}>
                          <option value="">Select a Category</option>
                          {menuitemCategory.map((item) => (
                              <option value={item.id} key={item.id}>{item.name} ({item.eng_name})</option>
                          ))};
                      </select> */}
                      
                  </div>
              </div>
              <div className="col-span-1 sm:col-span-1">
                  <label htmlFor="maharaj_id" className="block text-sm font-medium leading-6 text-gray-900">Maharaj Name</label>
                  <div className="mt-2">
                  <Select
                        classNamePrefix="select"
                        value={maharajList
                          .filter((item) => item.id == row.maharaj_id)
                          .map((item) => ({
                            value: item.id,
                            label: `${item.maharaj_name}`,
                          }))[0]}
                        name="maharaj_id"
                        options={maharajList.map((item) => ({
                          value: item.id,
                          label: `${item.maharaj_name}`,
                        }))}
                        onChange={(selectedOption) => {
                          handleMaharajChange(row.id, selectedOption);
                        }}
                        filterOption={(option, inputValue) => {
                          return option.data.label
                            .toLowerCase()
                            .includes(inputValue.toLowerCase());
                        }}
                      />
                      </div>
              </div>
              <div className="col-span-1 sm:col-span-1">
                <label
                  htmlFor="menu_item_id"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Menuitem
                </label>
                <div className="mt-2">
                  <Select
                    onFocus={() => setFilteredMenuItemList(menuitemList.filter((menuItem)=> menuItem.category == row.menuitem_category))}
                    classNamePrefix="select"
                    value={row.menu_item_id}
                    name="menu_item_id"
                    onChange={(selectedOption) => {
                      handleMenuitemChange(row.id, selectedOption);
                     
                    }}
                    options={filteredMenuItemList.map((item) => ({
                      value: item.id,
                      label: `${item.item_name} (${item.eng_name})`,
                    }))}

                    isDisabled={rowDisable.includes(row.id)}
                    filterOption={(option, inputValue) => {
                      return option.data.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase());
                    }}
                  />
                </div>
              </div>
              <div className="col-span-1 sm:col-span-2">
                <label
                  htmlFor="measurement"
                  className="block lg:text-sm font-medium leading-6 sm:text-xs text-gray-900"
                >
                  Measurement
                </label>
                <div className="grid grid-cols-3">
                  {methodList.map((method, index) => (
                    <div key={index}>
                      <label>
                        <input
                          type="checkbox"
                          name={method.name}
                          className="mr-2 "
                          disabled={rowDisable.includes(row.id)}
                          checked={row.measurements?.includes(method.id)}
                          onChange={(e) =>
                            {handleCheckboxChange(
                              row.id,
                              method.id,
                              e.target.checked
                            );}
                          }
                        />
                        {method.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-span-1 sm:col-span-1">
                
                 <button
                    onClick={handleAddRow}
                    disabled={isAddRowDisabled()}
                    className="bg-blue-500 mr-5 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Add Row
                  </button>
                <button
                    onClick={(e) => handleRefresh(e, row.menu_item_id.value)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-5"
                  >
                    Get Consumption data
                  </button>
                {row.id !== 1 && (
                  <button
                    onClick={() => handleRemoveRow(row.id)}
                    disabled={rowDisable.includes(row.id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Remove
                  </button>
                )}
              </div>
              </div>
              <div className="mt-10 grid grid-cols-1 sm:grid-cols-4 gap-x-5 gap-y-8" >
                <label className="flex items-center space-x-2"> <span>Track for Report</span> <input
                        type="checkbox"
                        checked={row.track_for_report}
                        onChange={(e) => handleTrackForReportChange(row.id, e.target.checked)}
                    /></label>
              </div>
              <div
              className="mt-10 grid grid-cols-1 sm:grid-cols-4 gap-x-5 gap-y-8 border-b-4 border-blue-500" 
              
            >
              {(row.measurements?.includes(1) || row.measurements?.includes(4)) && <div className="col-span-1 sm:col-span-1 mb-10">
                <label htmlFor="fg_consumption_per_person_kgs" className="block text-sm font-medium leading-6 text-gray-900">Fg Consumption per person (kgs)</label>
                  <div className="mt-2">
                    <input
                      id="fg_consumption_per_person_kgs"
                      name="fg_consumption_per_person_kgs"
                      type="number" 
                      step="0.01"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                      placeholder='Fg Consumption per person (kgs)'
                      value={row.fg_consumption_per_person_kgs}
                      onChange={(e) => {handleFgChange(e, row.id)} }
                      className={`${!row.fg_consumption_per_person_kgs || parseFloat(row.fg_consumption_per_person_kgs) === 0 ? 'bg-orange-50' : ''} block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                    />
                  </div>
              </div>}
              {(row.measurements?.includes(2) || row.measurements?.includes(5)) && <div className="col-span-1 sm:col-span-1 mb-10">
                <label htmlFor="fg_consumption_per_person_ltr" className="block text-sm font-medium leading-6 text-gray-900">Fg Consumption per person (Ltr)</label>
                  <div className="mt-2">
                    <input
                      id="fg_consumption_per_person_ltr"
                      name="fg_consumption_per_person_ltr"
                      type="number" 
                      step="0.01"
                      placeholder='Fg Consumption per person (ltr)'
                      value={row.fg_consumption_per_person_ltr}
                      onChange={(e) => handleFgChange(e, row.id)}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                      className={`${!row.fg_consumption_per_person_ltr || parseFloat(row.fg_consumption_per_person_ltr) === 0 ? 'bg-orange-50' : ''} block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                    />
                  </div>
              </div>}
              {(row.measurements?.includes(3) || row.measurements?.includes(6)) && <div className="col-span-1 sm:col-span-1 mb-10">
                <label htmlFor="fg_consumption_per_person_nos" className="block text-sm font-medium leading-6 text-gray-900">Fg Consumption per person (nos)</label>
                  <div className="mt-2">
                    <input
                      id="fg_consumption_per_person_nos"
                      name="fg_consumption_per_person_nos"
                      type="number" 
                      step="0.01"
                      placeholder='Fg Consumption per person (nos)'
                      value={row.fg_consumption_per_person_nos}
                      onChange={(e) => {handleFgChange(e, row.id)} }
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                      className={`${!row.fg_consumption_per_person_nos || parseFloat(row.fg_consumption_per_person_nos) === 0 ? 'bg-orange-50' : ''} block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                    />
                  </div>
              </div>}
              <div className="col-span-1 sm:col-span-1 mb-10">
                <label htmlFor="avg_density" className="block text-sm font-medium leading-6 text-gray-900">Avg Density</label>
                  <div className="mt-2">
                    <input
                      id="avg_density"
                      name="avg_density"
                      type="number" 
                      step="0.01"
                      placeholder='Avg Density'
                      value={row.avg_density}
                      onChange={(e) => {handleFgChange(e, row.id)} }
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                      className={`${!row.avg_density || parseFloat(row.avg_density) === 0 ? 'bg-orange-50' : ''} block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                    />
                  </div>
              </div>
              <div className="col-span-1 sm:col-span-1 mb-10">
                <label htmlFor="event_bowl_type" className="block text-sm font-medium leading-6 text-gray-900">Event Bowl Type</label>
                  <div className="mt-2">
                  <Select
                  classNamePrefix="select"
                  value={row.event_bowl_type}
                  name="event_bowl_type"
                  onChange={(selectedOption, name) => {
                    handleSelectRowChange(selectedOption, name, row.id);
                  }}
                  options={bowlList.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  filterOption={(option, inputValue) => {
                    return option.data.label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase());
                  }}
                />
                  </div>
              </div>
              {(row.measurements?.includes(1) || row.measurements?.includes(4)) &&<div className="col-span-1 sm:col-span-1 mb-10">
                <label htmlFor="avg_qty_in_1_bowl_kgs" className="block text-sm font-medium leading-6 text-gray-900">Avg Qty in 1 Bowl(Kgs)</label>
                  <div className="mt-2">
                    <input
                      id="avg_qty_in_1_bowl_kgs"
                      name="avg_qty_in_1_bowl_kgs"
                      type="number" 
                      step="0.01"
                      placeholder='Avg Qty In 1 Bowl Kgs'
                      value={row.avg_qty_in_1_bowl_kgs}
                      onChange={(e) => {handleFgChange(e, row.id)} }
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                      className={`${!row.avg_qty_in_1_bowl_kgs || parseFloat(row.avg_qty_in_1_bowl_kgs) === 0 ? 'bg-orange-50' : ''} block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                    />
                  </div>
              </div>}
              {(row.measurements?.includes(2) || row.measurements?.includes(5)) && <div className="col-span-1 sm:col-span-1 mb-10">
                <label htmlFor="avg_qty_in_1_bowl_ltr" className="block text-sm font-medium leading-6 text-gray-900">Avg Qty in 1 Bowl(Ltr)</label>
                  <div className="mt-2">
                    <input
                      id="avg_qty_in_1_bowl_ltr"
                      name="avg_qty_in_1_bowl_ltr"
                      type="number" 
                      step="0.01"
                      placeholder='Avg Qty In 1 Bowl Ltr'
                      value={row.avg_qty_in_1_bowl_ltr}
                      onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                      onChange={(e) => {handleFgChange(e, row.id)} }
                      onWheel={(e) => e.target.blur()}
                      className={`${!row.avg_qty_in_1_bowl_ltr || parseFloat(row.avg_qty_in_1_bowl_ltr) === 0 ? 'bg-orange-50' : ''} block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                    />
                  </div>
              </div>}
              
              {(row.measurements?.includes(3) || row.measurements?.includes(6)) &&<div className="col-span-1 sm:col-span-1 mb-10">
                <label htmlFor="avg_qty_in_1_bowl_nos" className="block text-sm font-medium leading-6 text-gray-900">Avg Qty in 1 Bowl(Nos)</label>
                  <div className="mt-2">
                    <input
                      id="avg_qty_in_1_bowl_nos"
                      name="avg_qty_in_1_bowl_nos"
                      type="number" 
                      step="0.01"
                      placeholder='Avg Qty In 1 Bowl nos'
                      value={row.avg_qty_in_1_bowl_nos}
                      onChange={(e) => {handleFgChange(e, row.id)} }
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                      className={`${!row.avg_qty_in_1_bowl_nos || parseFloat(row.avg_qty_in_1_bowl_nos) === 0 ? 'bg-orange-50' : ''} block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                    />
                  </div>
              </div>}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <Link to="/meal_master/list">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
        </Link>
        <button
          onClick={handleSubmit}
          type="submit"
          className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}
